<template>
    <div>
        <b-card
            title="Class Days"
        >
            <b-button
                v-for="day in days"
                :key="day"
                :variant="(selected.includes(day)) ? 'primary' : 'light'"
                @click="toggleSelection(day)"
                class="m-2"
            >
                {{ day }}
            </b-button>
        </b-card>
        <b-card
            class="mt-2"
            title="Class Frequency"
        >
            Weekly
            <div class="text-center">
                <b-button
                    @click="generateSchedule()"
                    variant="primary"
                >
                    Generate Schedule
                </b-button>
            </div>
        </b-card>
        <b-card
            title="Schedule"
            class="mt-2"
        >
            <div
                v-for="(daysOfMonth, month, idx) in schedule"
                :key="idx"
            >
                <hr/>
                <h5>{{ monthNames[month] }}</h5>
                <b-form-tags v-model="schedule[month]" no-outer-focus class="mb-2">
                    <template v-slot="{ tags, /* inputAttrs, inputHandlers, addTag, removeTag */ }">
                        <ul
                            class="list-unstyled d-inline-flex flex-wrap mb-0"
                        >
                            <b-card
                                v-for="(tag, idx) in tags"
                                :key="idx"
                                tag="li"
                                class="mt-1 mr-1"
                                body-class="py-1 pr-2 text-nowrap"
                            >
                                <b-checkbox
                                    :checked="true"
                                >{{ tag }}</b-checkbox>
                            </b-card>
                        </ul>
                    </template>
                </b-form-tags>
            </div>
        </b-card>
    </div>
</template>
<script>

function isLeapYear(year) { 
    return (((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0)); 
}

function getDaysInMonth(year, month) {
    return [31, (isLeapYear(year) ? 29 : 28), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month];
}

export default {
    data() {
        return {
            days: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
            monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
            selected: [],
            start_date: "2023-05-23T00:00-04:00",
            stop_date:  "2023-08-04T00:00-04:00",
            schedule: {}
        };
    },
    methods: {
        toggleSelection(day) {
            if (this.selected.includes(day)) {
                this.selected.splice(this.selected.indexOf(day), 1);
            } else {
                this.selected.push(day);
            }
            this.generateSchedule();
        },
        generateSchedule() {
            var schedule   = {};
            var start_date = new Date();
            var stop_date  = new Date();
            var month, date = 0, daysInMonth;
            var selectedDays = this.selected.map((day) => this.days.indexOf(day));

            start_date.setTime(Date.parse(this.start_date));
            stop_date.setTime (Date.parse(this.stop_date));
            
            console.log(start_date, stop_date);

            // Initialize month of days array.
            for (month = start_date.getMonth(); month <= stop_date.getMonth(); month++)
            {
                schedule[month] = [];
            }

            for (var selectedDay of selectedDays)
            {
                var current_date = new Date(start_date.getTime());
                for (month = current_date.getMonth(); month <= stop_date.getMonth(); month++)
                {
                    current_date.setMonth(month);

                    if (month == stop_date.getMonth())
                    {
                        daysInMonth = stop_date.getDate();
                    }
                    else
                    {
                        daysInMonth = getDaysInMonth(current_date.getFullYear(), month);
                    }

                    date = current_date.getDate() + (7 + selectedDay - current_date.getDay()) % 7;
                    while (date <= daysInMonth)
                    {
                        schedule[month].push(date);
                        date += 7;
                    }
                    current_date.setDate(1);    
                }
            }

            // Sort the selected days in the month.
            for (month = start_date.getMonth(); month <= stop_date.getMonth(); month++)
            {
                if (schedule[month].length == 0)
                {
                    delete schedule[month];
                }
                else
                {
                    schedule[month].sort((a, b) => a - b); 
                }
            }

            this.schedule = schedule;
        }
    }
}
</script>