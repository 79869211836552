<template>
    <b-container>
        <ClassDays></ClassDays>
        <!--
        <b-skeleton-wrapper :loading="loading">
            <template #loading>
                <b-card>
                    <b-skeleton width="85%"></b-skeleton>
                    <b-skeleton width="55%"></b-skeleton>
                    <b-skeleton width="70%"></b-skeleton>
                </b-card>
            </template>
            <b-jumbotron>
                <h4>
                    <router-link :to="{ name: 'semesters' }" class="mr-4">
                        <b-icon-arrow-left-circle scale="1.5"></b-icon-arrow-left-circle>
                    </router-link>
                    <span v-if="id">Edit Semester Settings</span>
                    <span v-else>Add Semester</span>
                </h4>
                <b-form class="mt-4">
                    <b-form-group
                        label="Name"
                    >
                        <b-input v-model="semesterModel.label" />
                    </b-form-group>
                    <b-form-group
                        label="Start Date"
                    >
                        <b-input type="datetime-local" v-model="semesterModel.start_time"></b-input>
                    </b-form-group>
                    <b-form-group
                        label="Stop Date"
                    >
                        <b-input type="datetime-local" v-model="semesterModel.stop_time"></b-input>
                    </b-form-group>
                    <b-form-group
                        label="First Class Date"
                    >
                        <b-input type="datetime-local" v-model="semesterModel.first_class_date"></b-input>
                    </b-form-group>
                    <b-form-group
                        label="Age Intermediate Period"
                    >
                        <b-input v-model.number="semesterModel.age_intermediate_period"></b-input>
                    </b-form-group>-->
                    <!--
                    <select-relationship
                        title="Registration Forms"
                        v-model="semesterModel.form_id"
                        :get-src="(id) => `/api/forms/${id}/fetchLess`"
                        list-src="/api/forms/admin/list"
                        list-key="forms"
                        item-key="form"
                        display-key="title"
                        get-key="id"
                        variant="primary"
                        class="m-3"
                    ></select-relationship>
                    -->
                    <!--<div class="text-center mt-4">
                        <b-button
                            :disabled="saving"
                            variant="primary"
                            size="lg"
                            @click="saveSemesterSettings()"
                            pill
                        >
                            <b-spinner v-if="saving" variant="light"></b-spinner>
                            <font-awesome-icon v-else icon="save"></font-awesome-icon>
                            Save
                        </b-button>
                    </div>
                </b-form>
            </b-jumbotron>
        </b-skeleton-wrapper>-->
    </b-container>
</template>
<script>
import { mapState } from 'vuex';
import ClassDays from './ClassDays.vue';
/* import SelectRelationship from '@/components/SelectRelationship.vue' */

export default {
    props: ["id"],
    /* components: { SelectRelationship }, */
    data: function () {
        return {
            loading: false,
            saving: false,
            semesterModel: {}
        };
    },
    mounted() {
        if (this.id)
            this.loadSemesterSettings();
    },
    methods: {
        loadSemesterSettings() {
            this.loading = true;
            this.$api.get(`/api/admin/get-semester`, { params: { id: this.id } }).then((response) => {
                this.semesterModel = response.data.semester;
                this.loading = false;
            });
        },
        saveSemesterSettings() {
            this.saving = true;
            this.$api.post(`/api/admin/save-semester`, this.semesterModel).then((response) => {
                if (this.id == this.semester.id)
                    this.$store.commit("updateSemester", response.data.semester);
                this.$bvToast.toast(response.data.message, {
                    title: "Notification",
                    autoHideDelay: 5000,
                    appendToast: true
                });
                this.saving = false;
            });
        }
    },
    computed: mapState(["semester"]),
    components: { ClassDays }
}
</script>